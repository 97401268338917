import type { AuthProviderProps } from 'react-oidc-context';
import { InMemoryWebStorage, Log as OidcLog, type User, type UserProfile, WebStorageStateStore } from 'oidc-client-ts';
import { config } from '../config';
import { store } from '../configuration/setup/store';
import { accessToken } from '../configuration/tokenHandling/accessToken';
import { type AccessToken, accessTokenStored, idTokenStored } from '../configuration/tokenHandling/tokenSlice';
import {
    setWasLoggedInBefore,
    type RioUserProfile,
    userProfileObtained,
    userSessionExpired,
    userSessionRenewed,
} from '../configuration/login/loginSlice';
import { configureFetchDisplayMessages } from '../configuration/lang/services';
import { mapUserProfile } from '../configuration/login/userProfile';
import { trace } from '../configuration/setup/trace';
import { routeStorage } from '../configuration/login/storage';

interface SessionRenewedResult {
    accessToken: AccessToken;
    idToken: UserProfile;
    profile: RioUserProfile;
    locale: string;
}

// enable logging for oidc-client
if (localStorage.getItem('oidcDebug') === 'enabled') {
    OidcLog.setLogger(console);
    OidcLog.setLevel(OidcLog.DEBUG);
}

const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.profile,
    locale: result.profile?.locale ?? 'en-GB',
    profile: mapUserProfile(result.profile),
});

export const oauthConfig = {
    onSessionExpired: () => {
        trace('oidcConfig: User session expired');
        accessToken.discardAccessToken();
        store.dispatch(accessTokenStored(null));
        store.dispatch(userSessionExpired());
    },
    onSessionRenewed: (user: User) => {
        trace('oidcConfig: User session renewed');
        const renewResult = adaptPublishedInfo(user);
        accessToken.saveAccessToken(renewResult.accessToken);
        store.dispatch(accessTokenStored(renewResult.accessToken));
        store.dispatch(idTokenStored(renewResult.idToken));
        store.dispatch(userProfileObtained(renewResult.profile));

        store.dispatch(setWasLoggedInBefore(true));
        store.dispatch(userSessionRenewed());

        const fetchDisplayMessages = configureFetchDisplayMessages(store);
        // You will need to get the user language yourself then
        // you may fetch the suitable messages. Depending
        // on when and from where you fetch the user settings you might
        // want to employ a loading spinner while the request is ongoing.
        void fetchDisplayMessages(renewResult.locale);
    },
};

const onSigninCallback = (): void => {
    trace('oidcConfig: onSigninCallback');
    const searchParams = new URLSearchParams(window.location.search);
    // Remove the code and state parameters from the url when you are redirected from the authorize page.
    if (window.location.pathname === '/redirect' && !searchParams.has('error')) {
        const initialRoute = routeStorage.getRoute();
        routeStorage.discardRoute();
        if (initialRoute) {
            // only set the location if there is an initial route
            // history.replaceState has to be used prior to location.replace, otherwise the initial route
            // will only be appended to the existing path (e.g. /redirect?code=foo#/initialRoute)
            trace(`Restore initial route: #/${initialRoute}`);
            window.history.replaceState({}, document.title, '/');
            window.location.replace(`#/${initialRoute}`);
        } else {
            trace('Redirect without initial route detected. Set location to "/"');
            window.history.replaceState({}, document.title, '/');
        }
    }
};

export const oidcConfig: AuthProviderProps = {
    authority: config.login.authority,
    client_id: config.login.clientId,
    redirect_uri: config.login.redirectUri,
    monitorSession: true,
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    onSigninCallback: onSigninCallback,
    post_logout_redirect_uri: config.postLogoutRedirectUri,
    // ...
};
