export interface ConfigState {
    backend: {
        MENU_SERVICE: string | undefined;
        ACCOUNTS_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: Array<string>;
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        mockTenant: string | undefined;
        // preventRedirect: boolean;
        redirectUri: string | undefined;
        // silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
    postLogoutRedirectUri: string | undefined;
}

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        ACCOUNTS_SERVICE: import.meta.env.VITE_ACCOUNTS_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
        oauthScope: ['openid', 'profile', 'email', 'phone'],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
        // preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        // silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    postLogoutRedirectUri: import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI,
};
